import React from 'react'
import {useParams} from 'react-router-dom'
import Grid from '@mui/material/Grid2'
import {useTranslation} from 'react-i18next'
import i18n, {LNG_FINANCIAL_GLOSSARY, LNG_SEO} from '../../i18n'
import Loan from '../Loan/Loan'
import MarkdownPage from '../../component/Markdown/MarkdownPage'
import Mortgage from '../Mortgage/Mortgage'
import {financialTermMetaSEO} from '../../util/SEOTools'
import {FIRST_SLUG_SELECTOR, GLOSSARY_LOAN, GLOSSARY_MORTGAGE} from '../../util/SlugHelper'
import './FinancialGlossaryTerm.css'
import LanguageFinancialGlossaryLinkHandler
    from '../../component/LanguageLinkHandler/LanguageFinancialGlossaryLinkHandler'

export default function FinancialGlossaryTerm() {

    const {t} = useTranslation([LNG_SEO, LNG_FINANCIAL_GLOSSARY])
    const language = i18n?.language?.split('-')[0] || 'en'

    const staticPages = {
        [FIRST_SLUG_SELECTOR[language][GLOSSARY_LOAN]]: <Loan/>,
        [FIRST_SLUG_SELECTOR[language][GLOSSARY_MORTGAGE]]: <Mortgage/>
    }

    const page = (useParams()?.first || '').toLowerCase()
    const subpage = (useParams()?.second || '').toLowerCase()

    const isStaticPage = Object.keys(staticPages).includes(page)
    const shouldShowStaticPage = (!subpage || subpage === '') && isStaticPage

    return (
        <Grid container id={'FinancialGlossaryTerm'}>
            {financialTermMetaSEO(t, subpage ? subpage : page)}
            <Grid item size={12}>
                {shouldShowStaticPage && <>{staticPages[page]}</>}
                {!shouldShowStaticPage && <MarkdownPage page={page} subpage={subpage}/>}
            </Grid>
            <LanguageFinancialGlossaryLinkHandler page={page} subpage={subpage}/>
        </Grid>
    )
}