import React from 'react'
import {useParams} from 'react-router-dom'
import {FINANCIAL_GLOSSARY_PAGE, MAIN_SLUG_SELECTOR, TOOLS_PAGE} from '../../util/SlugHelper'
import FinancialGlossary from '../FinancialGlossary/FinancialGlossary'
import Tools from '../Tool/Tools'

export default function MainLanguageSlugPage() {

    const PAGE_BY_SLUG = {
        [TOOLS_PAGE]: <Tools/>,
        [FINANCIAL_GLOSSARY_PAGE]: <FinancialGlossary/>
    }

    const language = (useParams()?.lang || '').toLowerCase()
    const page = (useParams()?.main || '').toLowerCase()

    return (PAGE_BY_SLUG[MAIN_SLUG_SELECTOR[language][page]])
}