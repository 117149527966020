import React from 'react'
import './Tools.css'
import {Trans, useTranslation} from 'react-i18next'
import {Link, useParams} from 'react-router-dom'
import {Typography} from '@mui/material'
import Grid from '@mui/material/Grid2'
import i18n from 'i18next'
import {metaSEO} from '../../util/SEOTools'
import {
    findI18nTermFromEn,
    FIRST_SLUG_SELECTOR,
    FRENCH_MORTGAGE_CALCULATOR,
    FRENCH_MORTGAGE_COMPARATOR,
    TOOLS_PAGE
} from '../../util/SlugHelper'
import {LNG_SEO} from '../../i18n'
import LanguageLinkHandler from '../../component/LanguageLinkHandler/LanguageLinkHandler'
import FrenchMortgageCalculator from '../FrenchMortgageCalculator/FrenchMortgageCalculator'
import FrenchMortgageComparator from '../FrenchMortgageComparative/FrenchMortgageComparator'

export default function Tools() {

    const PAGE_BY_SLUG = {
        [FRENCH_MORTGAGE_CALCULATOR]: <FrenchMortgageCalculator/>,
        [FRENCH_MORTGAGE_COMPARATOR]: <FrenchMortgageComparator/>
    }

    const {t} = useTranslation(LNG_SEO)
    const language = i18n?.language?.split('-')[0] || 'en'

    const first = (useParams()?.first || '').toLowerCase()

    return (
        <>
            {first && PAGE_BY_SLUG[FIRST_SLUG_SELECTOR[language][first]]}
            {!first && <Grid container id={'Tools'}>
                {metaSEO(t, 'tools')}
                <Grid item size={12}>
                    <Typography variant='h1' className={'page'}>
                        <Trans i18nKey={'tools'}/>
                    </Typography>
                    <hr style={{width: '100%', margin: '2em 0'}}/>
                    <ul>
                        <li>
                            <Typography variant='h2' className={'main'}>
                                <Trans i18nKey={'mortgage'}/>
                            </Typography>
                            <ul>
                                <li>
                                    <Link
                                        to={`/${language}/${findI18nTermFromEn(TOOLS_PAGE, language)}/${findI18nTermFromEn(FRENCH_MORTGAGE_CALCULATOR, language)}`}
                                        hrefLang={language}>
                                        <Typography variant='h3' className={'main'}>
                                            <Trans i18nKey={'tool_mortgage_calculator'}/>
                                        </Typography>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={`/${language}/${findI18nTermFromEn(TOOLS_PAGE, language)}/${findI18nTermFromEn(FRENCH_MORTGAGE_COMPARATOR, language)}`}>
                                        <Typography variant='h3' className={'main'}>
                                            <Trans i18nKey={'tool_mortgage_comparator'}/>
                                        </Typography>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Grid>
                <LanguageLinkHandler/>
            </Grid>
            }
        </>
    )
}