import React from 'react'
import './Loan.css'
import Grid from '@mui/material/Grid2'
import MarkdownPage from '../../component/Markdown/MarkdownPage'
import {ArrowOutward} from '@mui/icons-material'

export default function Loan() {

    return (
        <Grid container id={"Loan"}>
            <Grid item size={12}>
                <MarkdownPage page={'loan'} replacements={{
                    "test": <ArrowOutward style={{marginTop: "1em"}}/>
                }}/>
            </Grid>
        </Grid>
    )
}
