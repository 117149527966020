import axios from 'axios'
import config from '../config'

export const TOOLS_PAGE = 'tools'
export const FINANCIAL_GLOSSARY_PAGE = 'financial-glossary'
export const FRENCH_MORTGAGE_CALCULATOR = 'french-mortgage-calculator'
export const FRENCH_MORTGAGE_COMPARATOR = 'french-mortgage-comparator'
export const GLOSSARY_INVESTMENT = 'investment'
export const GLOSSARY_LOAN = 'loan'
export const GLOSSARY_MORTGAGE = 'mortgage'

const MAIN_SLUG_STRUCTURE = [
    {
        'en': TOOLS_PAGE,
        'es': 'herramientas'
    },
    {
        'en': FINANCIAL_GLOSSARY_PAGE,
        'es': 'glosario-financiero'
    }
]

const FIRST_SLUG_STRUCTURE = [
    {
        'en': FRENCH_MORTGAGE_CALCULATOR,
        'es': 'calculadora-hipotecas'
    },
    {
        'en': FRENCH_MORTGAGE_COMPARATOR,
        'es': 'comparador-hipotecas'
    },
    {
        'en': GLOSSARY_INVESTMENT,
        'es': 'inversion'
    },
    {
        'en': GLOSSARY_LOAN,
        'es': 'credito'
    },
    {
        'en': GLOSSARY_MORTGAGE,
        'es': 'hipoteca'
    }
]

function createSlugSelector(slugStructure) {
    const slugTranslator = {}
    for (const translations of slugStructure) {
        const enValue = translations['en']
        for (const [lang, value] of Object.entries(translations)) {
            if (!slugTranslator[lang]) {
                slugTranslator[lang] = {}
            }
            slugTranslator[lang][value] = enValue
        }
    }
    return slugTranslator
}

export const MAIN_SLUG_SELECTOR = createSlugSelector(MAIN_SLUG_STRUCTURE)
export const FIRST_SLUG_SELECTOR = createSlugSelector(FIRST_SLUG_STRUCTURE)

export async function fetchI18nTerms(language, terms) {
    try {
        const response = await axios.get(`${config.apiUrl}/api/v1/content/financial-glossary/i18n-glossary-term/${language}/${terms.join(',')}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (response.status === 200 && Boolean(response.data.exists)) {
            return response.data.content
        } else {
            return []
        }
    } catch (err) {
        console.log(err)
        return []
    }
}

export function findI18nTerm(language, term, candidate) {
    const combinedSlugStructure = [...MAIN_SLUG_STRUCTURE, ...FIRST_SLUG_STRUCTURE]
    for (const slug of combinedSlugStructure) {
        const termValue = Object.values(slug).find(val => val === term && slug[language] !== undefined)
        if (termValue) {
            return slug[candidate] || ''
        }
    }
    return ''
}

export function findI18nTermFromEn(term, candidate) {
    return findI18nTerm('en', term, candidate)
}

export function createRealPathname(url, language, candidate) {
    const segments = url.pathname.substring(language.length + 1).split('/')
    const restoredSegments = []
    const restoredQuery = url.search
    segments.forEach(segment => {
        if (!segment.includes('.')) {
            const i18nSegment = findI18nTerm(language, segment, candidate)
            restoredSegments.push(i18nSegment || segment)
        } else {
            restoredSegments.push(segment)
        }
    });
    return `/${candidate}${restoredSegments.join('/')}${restoredQuery}`;
}

export function createFinancialGlossaryRealPathname(url, page, subpage, i18nTerms, language, candidate) {
    const restoredSegments = []
    const restoredQuery = url.search

    const translatedPage = i18nTerms[page] ? i18nTerms[page][candidate] || page : page
    restoredSegments.push(translatedPage)
    if (subpage !== '') {
        const translatedSubpage = i18nTerms[subpage] ? i18nTerms[subpage][candidate] || subpage : subpage
        restoredSegments.push(translatedSubpage)
    }

    return `/${candidate}/${findI18nTermFromEn('financial-glossary', candidate)}/${restoredSegments.join('/')}${restoredQuery}`
}
