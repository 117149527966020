import React, {useEffect, useState} from 'react'
import './FinancialGlossary.css'
import {Typography} from '@mui/material'
import Grid from '@mui/material/Grid2'
import {Trans, useTranslation} from 'react-i18next'
import {Link, useParams} from 'react-router-dom'
import axios from 'axios'
import config from '../../config'
import {metaSEO} from '../../util/SEOTools'
import i18n, {LNG_FINANCIAL_GLOSSARY, LNG_SEO} from '../../i18n'
import LanguageLinkHandler from '../../component/LanguageLinkHandler/LanguageLinkHandler'
import FinancialGlossaryTerm from '../FinancialGlossaryTerm/FinancialGlossaryTerm'
import {findI18nTermFromEn} from '../../util/SlugHelper'

export default function FinancialGlossary() {

    const {t} = useTranslation(LNG_SEO)
    const language = i18n?.language?.split('-')[0] || 'en'

    const page = (useParams()?.first || '').toLowerCase()

    const financialGlossarySegment = findI18nTermFromEn('financial-glossary', language)

    const [terms, setTerns] = useState({})

    useEffect(() => {
        if (!page) {
            axios.get(`${config.apiUrl}/api/v1/content/financial-glossary/${language}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.status === 200) {
                    setTerns(response.data)
                }
            }).catch(err => console.log(err))
        }
    }, [language, page])

    return (
        <>
            {page && <FinancialGlossaryTerm/>}
            {!page && <Grid container id={'FinancialGlossary'}>
                {metaSEO(t, 'financial_glossary')}
                <Grid item size={12}>
                    <Typography variant='h1' className={'page'}>
                        <Trans i18nKey={'financial_glossary'}/>
                    </Typography>
                </Grid>
                <hr style={{width: '100%', margin: '2em 0'}}/>
                <Grid item size={12}>
                    <ul style={{marginTop: 0}}>
                        {terms['_'] && terms['_']
                            .sort((term, candidate) => term.localeCompare(candidate))
                            .map(mainTerm => (
                                <li key={`financial-glossary-main-term-${mainTerm}`}>
                                    <Link to={`/${language}/${financialGlossarySegment}/${mainTerm}`}
                                          hrefLang={language}>
                                        <Typography variant='h2' className={'main'}>
                                            <Trans i18nKey={`${mainTerm.toLowerCase()}`} ns={LNG_FINANCIAL_GLOSSARY}/>
                                        </Typography>
                                    </Link>
                                    <ul>
                                        {
                                            terms[mainTerm]?.sort((a, b) => a.localeCompare(b)).map((term, termIndex) => (
                                                <li key={`financial-glossary-${mainTerm}-${termIndex}`}>
                                                    <Link
                                                        to={`/${language}/${financialGlossarySegment}/${mainTerm}/${term}`}
                                                        hrefLang={language}
                                                    >
                                                        <Typography variant='h3' className={'main'}>
                                                            <Trans
                                                                i18nKey={`${term.toLowerCase().replace(/-/g, '_')}`}
                                                                ns={LNG_FINANCIAL_GLOSSARY}
                                                            />
                                                        </Typography>
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </li>
                            ))
                        }
                    </ul>
                </Grid>
                <LanguageLinkHandler/>
            </Grid>
            }
        </>
    )
}