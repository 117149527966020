import React from 'react'
import SuperLink from '../component/SuperLink/SuperLink'
import {Trans} from 'react-i18next'
import i18n from '../i18n'
import {
    findI18nTermFromEn,
    FRENCH_MORTGAGE_CALCULATOR,
    FRENCH_MORTGAGE_COMPARATOR,
    TOOLS_PAGE
} from '../util/SlugHelper'

const language = i18n?.language?.split('-')[0] || 'en'

const toolsSegment = findI18nTermFromEn(TOOLS_PAGE, language)

const mortgageCalculatorLink = `/${language}/${toolsSegment}/${findI18nTermFromEn(FRENCH_MORTGAGE_CALCULATOR, language)}`
const mortgageComparatorLink = `/${language}/${toolsSegment}/${findI18nTermFromEn(FRENCH_MORTGAGE_COMPARATOR, language)}`

export const HOME_SUPER_LINKS = [
    <SuperLink
        link={mortgageCalculatorLink}
        title={<Trans i18nKey={'tool_mortgage_calculator'}/>}
        color={'#5e4913'}
        highlightedColor={'#4b3d10'}
        opacity={0.9}
    />,
    <SuperLink
        link={mortgageComparatorLink}
        title={<Trans i18nKey={'tool_mortgage_comparator'}/>}
        color={'#5e4913'}
        highlightedColor={'#4b3d10'}
        opacity={0.9}
    />
]