import MarkdownContent from '../component/Markdown/MarkdownContent'
import {Trans} from 'react-i18next'
import DoneIcon from '@mui/icons-material/Done'
import HomeIcon from '@mui/icons-material/Home'
import React from 'react'
import MortgageBlock from '../component/BlockPanel/custom/MortgageBlock'
import i18n from '../i18n'
import {
    FINANCIAL_GLOSSARY_PAGE,
    findI18nTermFromEn,
    FRENCH_MORTGAGE_CALCULATOR,
    FRENCH_MORTGAGE_COMPARATOR,
    TOOLS_PAGE
} from '../util/SlugHelper'

const language = i18n?.language?.split('-')[0] || 'en'

export const HOME_BLOCKS = [
    {
        component: <MarkdownContent
            title={<Trans i18nKey={'block_manage_mortgages_effortless'}/>}
            icon={<HomeIcon sx={{fontSize: '1.2em', top: '0.2em', position: 'relative'}}/>}
            defaultContent={<MortgageBlock/>}
        />,
        size: 2,
        image: 'mortgage_background.jpg',
        url: `/${language}/${findI18nTermFromEn(TOOLS_PAGE, language)}/${findI18nTermFromEn(FRENCH_MORTGAGE_CALCULATOR, language)}`
    },
    {
        component: <MarkdownContent
            title={<Trans i18nKey={'tool_mortgage_comparator'}/>}
            icon={<HomeIcon sx={{fontSize: '1.2em', top: '0.2em', position: 'relative'}}/>}
            page={'home_comparator'}
        />,
        size: 1,
        image: 'mortgage_comparator.jpg',
        url: `/${language}/${findI18nTermFromEn(TOOLS_PAGE, language)}/${findI18nTermFromEn(FRENCH_MORTGAGE_COMPARATOR, language)}`
    },
    {
        component: <MarkdownContent
            title={<Trans i18nKey={'block_financial_glossary'}/>}
            icon={<DoneIcon sx={{fontSize: '1.2em', top: '0.2em', position: 'relative'}}/>}
            page={'home_glossary'}
        />,
        size: 3,
        image: 'financlal_glossary.jpg',
        url: `/${language}/${findI18nTermFromEn(FINANCIAL_GLOSSARY_PAGE, language)}`
    }
]