import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

export const DEFAULT_LANG = 'en'

export const LNG_GENERAL = 'general'
export const LNG_SEO = 'seo'
export const LNG_FINANCIAL_GLOSSARY = 'financial_glossary'

export const supportedLngs = {
    en: 'English',
    es: 'Español'
}

const currentLang = window.location.pathname.split('/')[1]

if (!currentLang || !supportedLngs.hasOwnProperty(currentLang)) {
    window.location.pathname = `/${DEFAULT_LANG}/`
}

await i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
        fallbackLng: DEFAULT_LANG,
        lng: currentLang || undefined,
        supportedLngs: Object.keys(supportedLngs),
        debug: false,
        ns: [LNG_GENERAL, LNG_SEO, LNG_FINANCIAL_GLOSSARY],
        defaultNS: LNG_GENERAL,
        preload: [LNG_GENERAL, LNG_SEO],
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '/locales/{{ns}}/{{lng}}.json',
        },
        react: {useSuspense: true}
    })

export default i18n
