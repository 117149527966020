import React, {useEffect, useState} from 'react'
import './Markdown.css'
import './MarkdownPage.css'
import {Trans} from 'react-i18next'
import Grid from '@mui/material/Grid2'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import axios from 'axios'
import config from '../../config'
import {Navigate} from 'react-router-dom'
import {Typography} from '@mui/material'
import i18n, {LNG_FINANCIAL_GLOSSARY} from '../../i18n'

export default function MarkdownPage({page, subpage, replacements = {}}) {

    const MARKDOWN_LINK_REGEX = /\[([^\]]*)\]\((.*?)\)/g

    const language = i18n?.language?.split('-')[0] || 'en'

    const [content, setContent] = useState([])
    const [redirect, setRedirect] = useState(false)

    const buildReactMarkdown = (content) => (
        <ReactMarkdown className={'Markdown'}
                       rehypePlugins={[rehypeRaw]}
                       remarkPlugins={[remarkGfm]}
                       components={{
                           html: true,
                           a: ({node, ...props}) => <a {...props} hrefLang={language}>{props.children}</a>
                       }}
        >
            {content && content}
        </ReactMarkdown>
    )

    const prefixUrlsWithLanguage = (markdownContent, language) => markdownContent.replace(MARKDOWN_LINK_REGEX, (match, text, url) => {
        if (!url) return match
        return `[${text}](${url.startsWith('/') ? `/${language}${url}` : url})`
    })

    const processReplacements = (markdown) => {
        const elements = []
        let remnantContent = markdown
        for (const [key, replacement] of Object.entries(replacements)) {
            const keyValue = `<!--${key}-->`
            const startIndex = remnantContent.indexOf(keyValue)
            if (startIndex === -1) continue
            elements.push(buildReactMarkdown(remnantContent.slice(0, startIndex)))
            remnantContent = remnantContent.slice(startIndex + keyValue.length)
            elements.push(replacement)
        }
        elements.push(buildReactMarkdown(remnantContent.replace(/<!--.*?-->/g, '')))
        return elements
    }

    useEffect(() => {
        const pageSubpage = (!subpage || subpage === '') ? page : `${page}/${subpage}`
        axios.get(`${config.apiUrl}/api/v1/content/financial-glossary/${language}/${pageSubpage}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.status === 200) {
                if (Boolean(response.data.exists) === true) {
                    const processedContent = prefixUrlsWithLanguage(response.data.content, language)
                    setContent(processReplacements(processedContent))
                }
            } else {
                setRedirect(true)
            }
        }).catch(err => console.log(err))
    }, [page, subpage])

    return (
        <>
            {redirect && <Navigate to={`/${language}/financial-glossary`}/>}
            <Grid container id={'MarkdownPage'}>
                <Grid item size={12}>
                    <Typography variant='h1' className={'page'}>
                        <Trans i18nKey={
                            (!subpage || subpage === '')
                                ? page
                                : `${subpage.replace(/-/g, '_')}`
                        }
                               ns={LNG_FINANCIAL_GLOSSARY}
                        />
                    </Typography>
                </Grid>
                <hr style={{width: '100%', margin: '2em 0'}}/>
                <Grid item size={12} style={{padding: '0 1em'}}>
                    {content.map((element, index) => (
                        <div key={`content-${index}`}>
                            {element}
                        </div>
                    ))}
                </Grid>
            </Grid>
        </>
    )
}
