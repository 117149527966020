import React from 'react'
import './LanguageLinkHandler.css'
import {supportedLngs} from '../../i18n'
import Grid from '@mui/material/Grid2'
import i18n from 'i18next'
import {Typography} from '@mui/material'
import {Trans} from 'react-i18next'
import {createRealPathname} from '../../util/SlugHelper'

export default function LanguageLinkHandler() {

    const url = new URL(window.location.href)
    const language = i18n?.language?.split('-')[0] || 'en'

    const handleClick = (event, newUrl) => {
        event.preventDefault()
        window.location.href = newUrl
    }

    return (
        <Grid container id={'LanguageLinkHandler'}>
            <Typography component='p'><Trans i18nKey={'language_link_handler_title'}/></Typography>
            <ul>
                {
                    Object.keys(supportedLngs)
                        .filter((candidate) => candidate !== language)
                        .map((candidate) => {
                            url.pathname = createRealPathname(url, language, candidate)
                            return (
                                <li key={`language-link-handler-list-${candidate}`}>
                                    <a href={url.toString()}
                                       hrefLang={candidate}
                                       onClick={(event) => handleClick(event, url.toString())}
                                    >
                                        {supportedLngs[candidate]}
                                    </a>
                                </li>
                            )
                        })
                }
            </ul>
        </Grid>
    )
}
