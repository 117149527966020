import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import './MortgageSelector.css'
import './MortgageSelector-media.css'
import {Button, MenuItem, Select, Typography} from '@mui/material'
import Grid from '@mui/material/Grid2'
import axios from 'axios'
import config from '../../../../config'
import {Trans} from 'react-i18next'
import {Warning} from '@mui/icons-material'
import {
    DEFAULT_FORM_AMORTIZATION_VALUES,
    DEFAULT_FORM_BONDING_VALUES,
    DEFAULT_FORM_GENERAL_INFO_VALUES,
    DEFAULT_FORM_LOAN_VALUES,
    NAME
} from '../../../../constant/Mortgage'
import {MORTGAGES_THRESHOLD} from '../../../../constant/UserAccount'
import {useNavigate} from 'react-router-dom'
import {findI18nTermFromEn, FRENCH_MORTGAGE_COMPARATOR, TOOLS_PAGE} from '../../../../util/SlugHelper'
import i18n from '../../../../i18n'

const MortgageSelector = forwardRef(({
                                         user, userLogged, setAmortizationMatrix,
                                         setFormGeneralInfo, setFormLoanValues,
                                         setFormAmortizationValues, setFormBondingValues,
                                         selectedMortgage, setSelectedMortgage,
                                         mortgageDetails, setMortgageDetails
                                     }, ref) => {

    const language = i18n?.language?.split('-')[0] || 'en'

    const [mortgageGeneralInfoList, setMortgageGeneralInfoList] = useState([])

    const navigate = useNavigate();

    const loadMortgage = (name) => {
        axios.get(`${config.apiUrl}/api/v1/mortgage/french-mortgage/name`, {
            params: {
                name: name
            },
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        }).then(response => {
            if (response.status === 200) {
                if (setAmortizationMatrix) setAmortizationMatrix(response.data['amortization_matrix'])
                if (setFormGeneralInfo) setFormGeneralInfo(response.data['mortgage']['general_info'])
                if (setFormLoanValues) setFormLoanValues(response.data['mortgage']['loan'])
                if (setFormAmortizationValues) setFormAmortizationValues(response.data['mortgage']['amortization'])
                if (setFormBondingValues) setFormBondingValues(response.data['mortgage']['bonding_list'])
                setMortgageDetails(false)
            }
        }).catch(error => {
            console.error('An error occurred while processing your request!', error)
        });
    }

    const newMortgage = () => {
        let counter = -1
        let mortgageName = ``
        do {
            counter++
            mortgageName = `New Mortgage ${mortgageGeneralInfoList.length === 0 ? '' : ` ${mortgageGeneralInfoList.length + counter}`}`
        } while (mortgageGeneralInfoList.some(mortgage => mortgage.name.toLowerCase() === mortgageName.toLowerCase()));

        setAmortizationMatrix(undefined)
        setFormGeneralInfo({
            ...DEFAULT_FORM_GENERAL_INFO_VALUES,
            [NAME]: mortgageName,
        })
        setFormLoanValues(DEFAULT_FORM_LOAN_VALUES)
        setFormAmortizationValues(DEFAULT_FORM_AMORTIZATION_VALUES)
        setFormBondingValues(DEFAULT_FORM_BONDING_VALUES)
        setMortgageDetails(true)
    }

    const manageMortgage = (generalInfoList, withSelectedMortgage = undefined) => {
        const defaultMortgage = generalInfoList.find(mortgage => mortgage.default)?.name || undefined
        const selectedMortgage = (withSelectedMortgage !== undefined)
            ? withSelectedMortgage
            : (generalInfoList.length > 0)
                ? (defaultMortgage !== undefined) ? defaultMortgage : generalInfoList[0].name
                : undefined
        setMortgageGeneralInfoList(generalInfoList);
        setSelectedMortgage(selectedMortgage);
        loadMortgage(selectedMortgage);
    }

    const deleteMortgage = () => {
        axios.delete(`${config.apiUrl}/api/v1/mortgage/french-mortgage/name`, {
            params: {
                name: selectedMortgage
            },
            withCredentials: true
        }).then(response => {
            if (response.status === 200) {
                manageMortgage(mortgageGeneralInfoList.filter(mortgage => mortgage.name !== selectedMortgage))
            }
        }).catch(error => {
            console.error('An error occurred while processing your request!', error);
        });
    }

    const appendNewMortgage = (generalInfo) => {
        if (mortgageGeneralInfoList.length === 0) {
            generalInfo.default = true
        }
        if (!mortgageGeneralInfoList.some(mortgage => mortgage.name === generalInfo.name)) {
            manageMortgage(
                (generalInfo.default)
                    ? [...mortgageGeneralInfoList.map(mortgage => ({...mortgage, default: false})), generalInfo]
                    : [...mortgageGeneralInfoList, generalInfo],
                generalInfo.name
            )
        } else if (generalInfo.default) {
            setMortgageGeneralInfoList([
                    ...mortgageGeneralInfoList.map
                    (mortgage => ({...mortgage, default: mortgage.name === generalInfo.name}))
                ]
            )
        }
    }

    useEffect(() => {
        if (userLogged) {
            axios.get(`${config.apiUrl}/api/v1/mortgage/french-mortgage/names/all`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            }).then(response => {
                if (response.status === 200) {
                    manageMortgage(response.data);
                }
            }).catch(error => {
                console.error('An error occurred while processing your request!', error);
            });
        } else {
            setMortgageGeneralInfoList([])
        }
    }, [userLogged]);

    useImperativeHandle(ref, () => ({
        notifyMortgageSubmit(generalInfo) {
            appendNewMortgage(generalInfo);
        }
    }));

    return (
        <Grid container id={'MortgageSelector'}>
            <Grid item size='grow' sx={{
                backgroundColor: 'rgba(0, 0, 0, 10%)',
                display: 'flex',
                border: 'solid rgba(255, 255, 255, 50%)',
                borderWidth: '1px',
                borderRadius: '0.5em',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '1em 5em',
                justifyContent: 'center',
                height: '100%'
            }}>
                {!userLogged &&
                    <Grid container sx={{marginBottom: '1em'}}>
                        <Grid size='grow' container alignItems='center' justifyContent='center'>
                            <Warning sx={{
                                color: 'wheat',
                                width: '2em',
                                height: '1.7em',
                                position: 'relative',
                            }}/>
                            <Typography variant={'body1'} sx={{paddingTop: '0.2em', textAlign: 'center'}}>
                                <Trans i18nKey={'manage_mortgage_advice'}/>
                            </Typography>
                        </Grid>
                    </Grid>
                }
                <>
                    {userLogged &&
                        <>
                            <Typography variant={'h6'}>
                                <Trans i18nKey={'manage_mortgage_selector'}/>
                            </Typography>
                            <Select
                                variant={'outlined'}
                                value={selectedMortgage}
                                onChange={(e) => {
                                    setSelectedMortgage(e.target.value);
                                    loadMortgage(e.target.value);
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            fontSize: '1.2em',
                                            backgroundColor: 'rgba(0, 0, 0, 50%)'
                                        },
                                    },
                                }}
                                sx={{
                                    color: 'white',
                                    backgroundColor: 'rgba(255, 255, 255, 10%)',
                                    borderRadius: '0.5em',
                                    fontSize: {xs: '2.5em', sm: '1.5em'},
                                    width: '15em',
                                    height: '2em',
                                    textAlign: 'center'
                                }}
                            >
                                {mortgageGeneralInfoList.map((generalInfo, index) => (
                                    <MenuItem key={`list-saved-mortgages-${index}`}
                                              value={generalInfo.name}
                                              sx={{
                                                  backgroundColor: 'rgba(0, 0, 0, 50%)',
                                                  color: 'white'
                                              }}
                                    >
                                        <Typography variant={'body1'} sx={{
                                            color: (generalInfo.default) ? 'wheat' : 'white',
                                            fontSize: {xs: '1.2em', sm: '1em'},
                                        }}>
                                            {generalInfo.name}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </>
                    }
                    <Grid container id={'mortgage-options'}
                          direction={{xs: 'column', sm: 'row'}}
                          style={{marginTop: '1em'}}
                    >
                        <Grid item auto>
                            <Button variant='contained' color='primary'
                                    sx={{
                                        width: {xs: '100%', sm: 'auto'},
                                        marginBottom: {xs: '0.5em', sm: '0'},
                                        marginRight: '2em'
                                    }}
                                    onClick={() => setMortgageDetails(!mortgageDetails)}
                                    disabled={userLogged && mortgageGeneralInfoList.length === 0}>
                                {mortgageDetails
                                    ? <Trans i18nKey={'hide_details'}/>
                                    : <Trans i18nKey={'show_details'}/>}
                            </Button>
                        </Grid>
                        <Grid item auto>
                            <Button variant='contained' color='primary'
                                    sx={{
                                        width: {xs: '100%', sm: 'auto'},
                                        marginBottom: {xs: '0.5em', sm: '0'}
                                    }}
                                    onClick={newMortgage}
                                    disabled={mortgageGeneralInfoList.length >= parseInt(user?.account?.features[MORTGAGES_THRESHOLD] || 0)}>
                                <Trans i18nKey={'new'}/>
                            </Button>
                        </Grid>
                        <Grid item auto>
                            <Button variant='contained' color='error'
                                    sx={{
                                        width: {xs: '100%', sm: 'auto'},
                                        marginBottom: {xs: '0.5em', sm: '0'}
                                    }}
                                    onClick={deleteMortgage}
                                    disabled={!userLogged || (!selectedMortgage || mortgageGeneralInfoList.length === 0)}>
                                <Trans i18nKey={'delete'}/>
                            </Button>
                        </Grid>
                        <Grid item auto>
                            <Button variant='contained' color='primary'
                                    sx={{
                                        width: {xs: '100%', sm: 'auto'},
                                        marginRight: '2em'
                                    }}
                                    onClick={() => navigate(`/${language}/${findI18nTermFromEn(TOOLS_PAGE, language)}/${findI18nTermFromEn(FRENCH_MORTGAGE_COMPARATOR, language)}`)}
                                    disabled={mortgageGeneralInfoList.length <= 1}>
                                <Trans i18nKey={'comparator'}/>
                            </Button>
                        </Grid>
                    </Grid>
                    {userLogged && mortgageGeneralInfoList.length >= parseInt(user?.account?.features[MORTGAGES_THRESHOLD] || 0) &&
                        <Typography variant={'body2'} sx={{marginTop: '1em', textAlign: 'center'}}>
                            <Trans i18nKey={'limited_mortgages_reached'} values={{
                                'account_type': user?.account?.type.toUpperCase() || ''
                            }}/>
                        </Typography>
                    }
                </>
            </Grid>
        </Grid>
    )
});

export default MortgageSelector;