import React, {useEffect, useState} from 'react'
import './FrenchMortgageComparator.css'
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import axios from 'axios'
import config from '../../config'
import {checkAuthentication} from '../../global/CheckAuthentication'
import {USER_LOGIN} from '../../constant/EventBus'
import {PubSub} from '../../config/EventBus'
import {ArrowUpward} from '@mui/icons-material'
import {useNavigate} from 'react-router-dom'
import {Trans, useTranslation} from 'react-i18next'
import i18n, {LNG_SEO} from '../../i18n'
import {metaSEO} from '../../util/SEOTools'
import LanguageLinkHandler from '../../component/LanguageLinkHandler/LanguageLinkHandler'
import {findI18nTermFromEn, FRENCH_MORTGAGE_CALCULATOR, TOOLS_PAGE} from '../../util/SlugHelper'

export default function FrenchMortgageComparator({}) {

    const {t} = useTranslation(LNG_SEO)
    const language = i18n?.language?.split('-')[0] || 'en'

    const [bestMortgage, setBestMortgage] = useState(undefined)
    const [others, setOthers] = useState([])

    const navigate = useNavigate()

    const fireCalculations = (comparative) => {
        if (comparative.length > 0) {
            setBestMortgage(comparative[0])
            setOthers(comparative.slice(1))
        }
    }

    const loadMortgageComparative = () => {
        axios.get(`${config.apiUrl}/api/v1/mortgage/french-mortgage/comparative`, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        }).then(response => {
            if (response.status === 200) {
                fireCalculations(response.data)
            }
        }).catch(error => {
            console.error('An error occurred while processing your request!', error)
        })
    }

    useEffect(() => {
        checkAuthentication((user) => {
            if (user !== undefined) {
                loadMortgageComparative()
            }
        })

        PubSub.on(USER_LOGIN, loadMortgageComparative);
        return () => {
            PubSub.off(USER_LOGIN, loadMortgageComparative)
        }
    }, [])

    return (
        <Grid container id={'FrenchMortgageComparator'}>
            {metaSEO(t, 'tool/french_mortgage_comparator')}
            <Grid item size={12}>
                <Typography variant='h1' className={'page'}>
                    <Trans i18nKey={'french_mortgage_comparative'}/>
                </Typography>
                <hr style={{width: '100%', margin: '2em 0'}}/>
                <TableContainer id={'matrix'} component={Paper}
                                style={{backgroundColor: 'transparent', marginTop: '1em'}}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow class={'head'}>
                                <TableCell className={'row name'}><Trans i18nKey={'loan_name'}/></TableCell>
                                <TableCell className={'row'}><Trans i18nKey={'loan_asset'}/></TableCell>
                                <TableCell className={'row'}><Trans i18nKey={'loan_principal'}/></TableCell>
                                <TableCell className={'row'}><Trans i18nKey={'loan_fixed_interest_rate'}/></TableCell>
                                <TableCell className={'row'}><Trans
                                    i18nKey={'loan_variable_interest_rate'}/></TableCell>
                                <TableCell className={'row'}><Trans i18nKey={'accumulated_bonding'}/></TableCell>
                                <TableCell className={'row'}><Trans i18nKey={'total_cost'}/></TableCell>
                                <TableCell className={'row'}><Trans i18nKey={'underperformance'}/></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bestMortgage &&
                                <TableRow className={'best'} key={`amortization-matrix-name`}>
                                    <TableCell className={'row name'}>
                                        {bestMortgage.name}
                                    </TableCell>
                                    <TableCell className={'row'}>
                                        {parseFloat(bestMortgage.total).toFixed(2)}
                                    </TableCell>
                                    <TableCell className={'row'}>
                                        {parseFloat(bestMortgage.principal).toFixed(2)}
                                    </TableCell>
                                    <TableCell className={'row'}>
                                        {parseFloat(bestMortgage.total_fixed_interest_rate).toFixed(2)}
                                    </TableCell>
                                    <TableCell className={'row'}>
                                        {parseFloat(bestMortgage.total_variable_interest_rate).toFixed(2)}
                                    </TableCell>
                                    <TableCell className={'row'}>
                                        {parseFloat(bestMortgage.accumulated_bonding).toFixed(2)}
                                    </TableCell>
                                    <TableCell className={'row'}>
                                        {parseFloat(bestMortgage.total_cost).toFixed(2)}
                                    </TableCell>
                                    <TableCell className={'row'}/>
                                </TableRow>
                            }
                            {others && others.map((item, index) => (
                                <TableRow className={'under'} key={`amortization-matrix-${item.name}`}>
                                    <TableCell className={'row name'}>
                                        {item.name}
                                    </TableCell>
                                    <TableCell className={'row'}>
                                        {parseFloat(item.total).toFixed(2)}
                                    </TableCell>
                                    <TableCell className={'row'}>
                                        {parseFloat(item.principal).toFixed(2)}
                                    </TableCell>
                                    <TableCell className={'row'}>
                                        {parseFloat(item.total_fixed_interest_rate).toFixed(2)}
                                    </TableCell>
                                    <TableCell className={'row'}>
                                        {parseFloat(item.total_variable_interest_rate).toFixed(2)}
                                    </TableCell>
                                    <TableCell className={'row'}>
                                        {parseFloat(item.accumulated_bonding).toFixed(2)}
                                    </TableCell>
                                    <TableCell className={'row'}>
                                        {parseFloat(item.total_cost).toFixed(2)}
                                    </TableCell>
                                    <TableCell className={'row'}>
                                        {item.total_cost !== bestMortgage.total_cost &&
                                            <Box sx={{
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                                <ArrowUpward style={{color: 'red', marginRight: '0.1em'}}/>
                                                {(((item.total_cost - bestMortgage.total_cost) / bestMortgage.total_cost) * 100).toFixed(2)}%
                                            </Box>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid size={12} sx={{display: 'flex', justifyContent: 'center', marginTop: '3em', width: '100%'}}>
                <Button variant='contained' color='success'
                        style={{marginLeft: '2em'}}
                        onClick={() => navigate(`/${language}/${findI18nTermFromEn(TOOLS_PAGE, language)}/${findI18nTermFromEn(FRENCH_MORTGAGE_CALCULATOR, language)}`)}
                >
                    <Trans i18nKey={'french_manage_mortgages'}/>
                </Button>
            </Grid>
            <LanguageLinkHandler/>
        </Grid>
    )
}
