import React, {useEffect, useRef, useState} from 'react'
import './FrenchMortgageCalculator.css'
import axios from 'axios'
import config from '../../config'
import {Box, Button, Tab, Typography} from '@mui/material'
import Grid from '@mui/material/Grid2'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import {useForm} from 'react-hook-form'
import LoanFormFrenchMortgageCalculator
    from '../../component/mortgage/module/LoanFormFrenchMortgageCalculator/LoanFormFrenchMortgageCalculator'
import {Trans, useTranslation} from 'react-i18next'
import AmortizationFormFrenchMortgageCalculator
    from '../../component/mortgage/module/AmortizationFormFrenchMortgageCalculator/AmortizationFormFrenchMortgageCalculator'
import {
    AMORTIZATION_QUANTITY,
    BONDING_YEARS,
    DEFAULT_FORM_AMORTIZATION_VALUES,
    DEFAULT_FORM_BONDING_VALUES,
    DEFAULT_FORM_GENERAL_INFO_VALUES,
    DEFAULT_FORM_LOAN_VALUES,
    LOAN_FIXED_INTEREST_RATE,
    LOAN_FIXED_YEARS,
    LOAN_PRINCIPAL,
    LOAN_VARIABLE_YEARS,
    LOAN_YEARS,
    NAME
} from '../../constant/Mortgage'
import BondingFormFrenchMortgageCalculator
    from '../../component/mortgage/module/BondingFormFrenchMortgageCalculator/BondingFormFrenchMortgageCalculator'
import {isNotEmpty} from '../../util/FormFunctions'
import {FRENCH_MORTGAGE_SUBMIT, USER_LOGIN} from '../../constant/EventBus'
import FrenchAmortizationMatrix from '../../component/mortgage/FrenchAmortizationMatrix/FrenchAmortizationMatrix'
import {PubSub} from '../../config/EventBus'
import {checkAuthentication} from '../../global/CheckAuthentication'
import MortgageSelector from '../../component/mortgage/module/MortgageSelector/MortgageSelector'
import MortgageOverview from '../../component/mortgage/module/MortgageOverview/MortgageOverview'
import {
    FrenchAmortizationCostPieChart
} from '../../component/mortgage/chart/FrenchAmortizationCostPieChart/FrenchAmortizationCostPieChart'
import FrenchMortgageInterestRateMatrix
    from '../../component/mortgage/FrenchMortgageInterestRateMatrix/FrenchMortgageInterestRateMatrix'
import {
    FrenchAmortizationCostChart
} from '../../component/mortgage/chart/FrenchAmortizationCostChart/FrenchAmortizationCostChart'
import {
    FrenchAmortizationCostPercentageProgressionChart
} from '../../component/mortgage/chart/FrenchAmortizationCostPercentageProgressionChart/FrenchAmortizationCostPercentageProgressionChart'
import {metaSEO} from "../../util/SEOTools";
import {LNG_SEO} from "../../i18n";
import LanguageLinkHandler from "../../component/LanguageLinkHandler/LanguageLinkHandler";

/**
 * FrenchMortgageCalculator is a function component that provides a French mortgage calculator UI.
 *
 * This component contains form inputs for loan, personal information, amortization, and bonding.
 * It uses useForm hook from react-hook-form library for form handling.
 * It also uses axios library for making API calls to the server.
 *
 * The component renders a form with multiple tabs, allowing the user to input the required information for calculating a French mortgage.
 * The onSubmit function is*/
export default function FrenchMortgageCalculator() {

    const {t: i18n} = useTranslation([LNG_SEO])

    const {register, handleSubmit} = useForm()

    const [amortizationMatrix, setAmortizationMatrix] = useState()
    const [user, setUser] = useState(undefined)
    const [userLogged, setUserLogged] = useState(false)

    const [tab, setTab] = useState('1')
    const handleChangeTab = (event, newTab) => setTab(newTab)

    const [selectedMortgage, setSelectedMortgage] = useState('')

    /* General Info Form */
    const [formGeneralInfo, setFormGeneralInfo] = useState(DEFAULT_FORM_GENERAL_INFO_VALUES)
    const [isFormGeneralInfoValid, setIsFormGeneralInfoValid] = useState(false)
    /* End General Info Form */

    /* Loan Form */
    const [formLoanValues, setFormLoanValues] = useState(DEFAULT_FORM_LOAN_VALUES)
    const [isFormLoanValid, setIsFormLoanValid] = useState(false)
    /* End Loan Form */

    /* Amortization Form */
    const [formAmortizationValues, setFormAmortizationValues] = useState(DEFAULT_FORM_AMORTIZATION_VALUES)
    const [isFormAmortizationValid, setIsFormAmortizationValid] = useState(false)
    /* End Amortization Form */

    /* Bonding Form */
    const [formBondingValues, setFormBondingValues] = useState(DEFAULT_FORM_BONDING_VALUES)
    const [isFormBondingValid, setIsFormBondingValid] = useState(false)
    /* End Bonding Form */

    const [mortgageDetails, setMortgageDetails] = useState(true)

    const mortgageSelectorRef = useRef()

    /**
     * onSubmit is a function that handles the submission of form data to the server.
     *
     * The form data includes the information needed to generate the amortization matrix
     * request.
     *
     * @param {Function} _ - Unused parameter.
     * @returns {void}
     */
    const onSubmit = (_) => {
        let globalFormValues = {
            'general_info': (isFormGeneralInfoValid) ? formGeneralInfo : DEFAULT_FORM_GENERAL_INFO_VALUES,
            'loan': (isFormLoanValid) ? formLoanValues : {},
            'amortization': (isFormAmortizationValid) ? formAmortizationValues : null,
            'bonding_list': formBondingValues
        }
        axios.post(`${config.apiUrl}/api/v1/mortgage/french-mortgage`, globalFormValues, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        }).then(response => {
            setMortgageDetails(false)
            setAmortizationMatrix(response.data)
            mortgageSelectorRef.current.notifyMortgageSubmit(formGeneralInfo)
            PubSub.emit(FRENCH_MORTGAGE_SUBMIT, formGeneralInfo)
        }).catch(error => {
            console.error('There was an error!', error)
        })
    }

    const userEventHandler = user => {
        setUser(user)
        setUserLogged(!!user)
    };

    useEffect(() => {
        checkAuthentication();
        PubSub.on(USER_LOGIN, userEventHandler)

        return () => {
            PubSub.off(USER_LOGIN, userEventHandler)
        };
    }, [])

    useEffect(() => {
        setIsFormGeneralInfoValid(
            NAME in formGeneralInfo && isNotEmpty(formGeneralInfo[NAME])
        )
    }, [formGeneralInfo])

    useEffect(() => {
        const updatedLoanValues = {
            ...formLoanValues,
            [LOAN_VARIABLE_YEARS]: parseInt(formLoanValues[LOAN_YEARS]) - parseInt(formLoanValues[LOAN_FIXED_YEARS])
        };
        // Only update the state if there's an actual change to avoid infinite loop
        if (JSON.stringify(updatedLoanValues) !== JSON.stringify(formLoanValues)) {
            setFormLoanValues(updatedLoanValues)
        }
        setIsFormLoanValid(
            LOAN_PRINCIPAL in updatedLoanValues && LOAN_FIXED_INTEREST_RATE in updatedLoanValues && LOAN_YEARS in updatedLoanValues &&
            isNotEmpty(updatedLoanValues[LOAN_PRINCIPAL]) && isNotEmpty(updatedLoanValues[LOAN_FIXED_INTEREST_RATE]) && isNotEmpty(updatedLoanValues[LOAN_YEARS])
        );
        if (isNotEmpty(updatedLoanValues[LOAN_YEARS]) && formBondingValues.length > 0) {
            const refYears = parseInt(updatedLoanValues[LOAN_YEARS]);
            const bondingsModified = formBondingValues.map(bonding => {
                const years = bonding[BONDING_YEARS]
                years[0] = (years[0] > refYears) ? refYears : years[0]
                years[1] = (years[1] > refYears) ? refYears : years[1]
                return {...bonding, bonding_years: years}
            });
            setFormBondingValues(bondingsModified)
        }
    }, [formLoanValues])

    useEffect(() => {
        setIsFormAmortizationValid(
            AMORTIZATION_QUANTITY in formAmortizationValues &&
            isNotEmpty(formAmortizationValues[AMORTIZATION_QUANTITY]) &&
            parseFloat(formAmortizationValues[AMORTIZATION_QUANTITY]) !== 0.0
        )
    }, [formAmortizationValues])

    useEffect(() => {
        setIsFormBondingValid(formBondingValues && formBondingValues.length > 0)
    }, [formBondingValues])

    return (
        <Grid container id={'FrenchMortgageCalculator'}>
            {metaSEO(i18n, 'tool/french_mortgage_calculator')}
            <Grid item size={12}>
                <Typography variant='h1' className={'page'}>
                    <Trans i18nKey={'tool_mortgage_calculator'}/>
                </Typography>
                <hr style={{width: '100%', margin: '2em 0'}}/>
                <MortgageSelector
                    ref={mortgageSelectorRef}
                    user={user}
                    userLogged={userLogged}
                    setAmortizationMatrix={setAmortizationMatrix}
                    setFormGeneralInfo={setFormGeneralInfo}
                    setFormLoanValues={setFormLoanValues}
                    setFormAmortizationValues={setFormAmortizationValues}
                    setFormBondingValues={setFormBondingValues}
                    selectedMortgage={selectedMortgage}
                    setSelectedMortgage={setSelectedMortgage}
                    mortgageDetails={mortgageDetails}
                    setMortgageDetails={setMortgageDetails}
                />
                <form
                    id='CalculatorForm' autoComplete='off' onSubmit={handleSubmit(onSubmit)}
                    style={{display: mortgageDetails ? 'block' : 'none'}}
                >
                    <TabContext value={tab}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={handleChangeTab} aria-label='lab API tabs example'>
                                <Tab className={'Tab'}
                                     label={<Trans i18nKey='loan'/>}
                                     value='1'
                                     sx={{color: isFormLoanValid ? '#228700' : '#737373'}}
                                />
                                <Tab className={'Tab'}
                                     label={<Trans i18nKey='amortization'/>}
                                     value='2'
                                     sx={{color: isFormAmortizationValid ? '#228700' : '#737373'}}
                                />
                                <Tab className={'Tab'}
                                     label={<Trans i18nKey='bonding'/>}
                                     value='3'
                                     sx={{color: isFormBondingValid ? '#228700' : '#737373'}}
                                />
                            </TabList>
                        </Box>
                        <Button variant='contained'
                                type='submit'
                                disabled={!isFormLoanValid}
                                sx={{
                                    width: '30em',
                                    maxWidth: '100%',
                                    height: '2em',
                                    fontSize: '2em',
                                    margin: '0.5em auto',
                                    display: 'flex'
                                }}>
                            <Trans i18nKey='calculate_mortgage'/>
                        </Button>
                        <TabPanel value='1'>
                            <LoanFormFrenchMortgageCalculator
                                formRegister={register} formValues={formLoanValues}
                                setFormValues={setFormLoanValues} formGeneralInfo={formGeneralInfo}
                                setFormGeneralInfo={setFormGeneralInfo}
                                userLogged={userLogged} selectedMortgage={selectedMortgage}
                            />
                        </TabPanel>
                        <TabPanel value='2'>
                            <AmortizationFormFrenchMortgageCalculator
                                formRegister={register} formValues={formAmortizationValues}
                                setFormValues={setFormAmortizationValues} userLogged={userLogged}
                            />
                        </TabPanel>
                        <TabPanel value='3'>
                            <BondingFormFrenchMortgageCalculator
                                formLoanValues={formLoanValues} formValues={formBondingValues}
                                setFormValues={setFormBondingValues} user={user} userLogged={userLogged}
                            />
                        </TabPanel>
                    </TabContext>
                </form>
                {amortizationMatrix && formLoanValues &&
                    <Grid container direction={{sm: 'column', md: 'row'}}>
                        <Grid item className={'analysis-block'} size={12}
                              style={{textAlign: 'center', padding: '2em 0'}}>
                            <Typography id={'mortgage-analysis'} variant='h4' style={{minWidth: '100%'}}>
                                {userLogged && <span style={{color: 'wheat'}}>{formGeneralInfo[NAME]}&nbsp;</span>}
                                <Trans i18nKey='analysis'/>
                            </Typography>
                        </Grid>
                        <Grid item className={'analysis-block'} size={{sm: 12, md: 8}}>
                            <MortgageOverview formValues={formLoanValues} amortizationMatrix={amortizationMatrix}/>
                        </Grid>
                        <Grid item className={'analysis-block'} size={{sm: 12, md: 4}}>
                            <FrenchAmortizationCostPieChart amortizationMatrix={amortizationMatrix}/>
                            <FrenchMortgageInterestRateMatrix amortizationMatrix={amortizationMatrix}/>
                        </Grid>
                        <Grid item className={'analysis-block'} size={{sm: 12, md: 6}}>
                            <FrenchAmortizationCostChart amortizationMatrix={amortizationMatrix}/>
                        </Grid>
                        <Grid item className={'analysis-block'} size={{sm: 12, md: 6}}>
                            <FrenchAmortizationCostPercentageProgressionChart amortizationMatrix={amortizationMatrix}/>
                        </Grid>
                    </Grid>
                }
                <FrenchAmortizationMatrix amortizationMatrix={amortizationMatrix}/>
            </Grid>
            <LanguageLinkHandler/>
        </Grid>
    )
}