import React, {useEffect, useState} from 'react'
import './LanguageLinkHandler.css'
import {supportedLngs} from '../../i18n'
import Grid from '@mui/material/Grid2'
import i18n from 'i18next'
import {Typography} from '@mui/material'
import {Trans} from 'react-i18next'
import {createFinancialGlossaryRealPathname, fetchI18nTerms} from '../../util/SlugHelper'

export default function LanguageFinancialGlossaryLinkHandler({page, subpage}) {

    const url = new URL(window.location.href)
    const language = i18n?.language?.split('-')[0] || 'en'

    const handleClick = (event, newUrl) => {
        event.preventDefault()
        window.location.href = newUrl
    }

    const [i18nTerms, setI18nTerms] = useState({})

    const updateI18nTerms = async () => {
        const fetchedTerms = await fetchI18nTerms(language, [page, subpage].filter(Boolean))
        setI18nTerms(fetchedTerms)
    }

    useEffect(() => {
        updateI18nTerms()
    }, [language])

    return (
        <Grid container id={'LanguageLinkHandler'}>
            <Typography component='p'><Trans i18nKey={'language_link_handler_title'}/></Typography>
            <ul>
                {i18nTerms && Object.keys(supportedLngs)
                    .filter((candidate) => candidate !== language)
                    .map((candidate) => {
                        url.pathname = createFinancialGlossaryRealPathname(url, page, subpage, i18nTerms, language, candidate)
                        return (
                            <li key={`language-link-handler-list-${candidate}`}>
                                <a href={url.toString()}
                                   hrefLang={candidate}
                                   onClick={(event) => handleClick(event, url.toString())}
                                >
                                    {supportedLngs[candidate]}
                                </a>
                            </li>
                        )
                    })
                }
            </ul>
        </Grid>
    )
}
